/* cairo-regular - latin_arabic */
@font-face {
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/cairo-v10-latin_arabic/cairo-v10-latin_arabic-regular.eot'); /* IE9 Compat Modes */
  src:
    local(''),
    url('/assets/fonts/cairo-v10-latin_arabic/cairo-v10-latin_arabic-regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('/assets/fonts/cairo-v10-latin_arabic/cairo-v10-latin_arabic-regular.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('/assets/fonts/cairo-v10-latin_arabic/cairo-v10-latin_arabic-regular.woff')
      format('woff'),
    /* Modern Browsers */
      url('/assets/fonts/cairo-v10-latin_arabic/cairo-v10-latin_arabic-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('/assets/fonts/cairo-v10-latin_arabic/cairo-v10-latin_arabic-regular.svg#Cairo')
      format('svg'); /* Legacy iOS */
}

/* cairo-700 - latin_arabic */
@font-face {
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 700;
  src: url('/assets/fonts/cairo-v10-latin_arabic/cairo-v10-latin_arabic-700.eot'); /* IE9 Compat Modes */
  src:
    local(''),
    url('/assets/fonts/cairo-v10-latin_arabic/cairo-v10-latin_arabic-700.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('/assets/fonts/cairo-v10-latin_arabic/cairo-v10-latin_arabic-700.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('/assets/fonts/cairo-v10-latin_arabic/cairo-v10-latin_arabic-700.woff')
      format('woff'),
    /* Modern Browsers */
      url('/assets/fonts/cairo-v10-latin_arabic/cairo-v10-latin_arabic-700.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('/assets/fonts/cairo-v10-latin_arabic/cairo-v10-latin_arabic-700.svg#Cairo')
      format('svg'); /* Legacy iOS */
}
