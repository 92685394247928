/* stylelint-disable */

// Color Palettes
$popsy-colors-primary: (
  50: #ffffff,
  100: #facada,
  // Primary Light
  200: #e392af,
  300: #ee568a,
  400: #e91e63,
  500: #d43864,
  // Primary
  600: #d41556,
  700: #b8124a,
  800: #9c0f3f,
  900: #800d34,
  A100: #ffffff,
  A200: #ffa1c1,
  A400: #fc3e7e,
  A700: #f13071,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$popsy-colors-secondary: (
  50: #c6e1f0,
  // Key Color
  100: #5aa9f1,
  200: #2f6adf,
  // Secondary Light
  300: #3f6ad7,
  400: #0743bc,
  // Secondary
  500: #1d44b4,
  // Secondary Dark
  600: #16348a,
  700: #1b2b4b,
  800: #0c0c37,
  900: #07071e,
  A100: #a2a2fc,
  A200: #3f3ff9,
  A400: #1717bb,
  A700: #1d1d9b,
  contrast: (
    50: #000000,
    100: #000000,
    200: #ffffff,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);
$popsy-colors-gray: (
  50: #ffffff,
  // Light
  100: #fafbfc,
  // Background
  200: #f3f4f7,
  // Gray 04
  300: #a5adb9,
  // Gray 03
  400: #6d7789,
  // Gray 02
  500: #44506b,
  // Gray 01
  600: #172b4d,
  // Dark
  700: #1b3b4b,
  800: #000000,
  900: #000000,
  A100: #b87b7b,
  A200: #854848,
  A400: #3b2c2c,
  A700: #292525,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$popsy-colors-loggi: (
  // unconfirmed
  50: #51b8f9,
  // confirmed
  100: #96e6a1,
  // cancelled
  200: #feada6,
  // delivered
  300: #9795f0,
  // delivering
  400: #6ab255,
  500: #ffffff,
  600: #ffffff,
  700: #ffffff,
  800: #ffffff,
  900: #ffffff,
  A100: #ffffff,
  A200: #ffffff,
  A400: #ffffff,
  A700: #ffffff,
  contrast: (
    50: #ffffff,
    100: #ffffff,
    200: #ffffff,
    300: #ffffff,
    400: #ffffff,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  )
);

/* stylelint-enable */
