$header-size: 64px;
$footer-content-height: 290px;
$footer-extended-content-height: 240px;
$footer-extended-2-content-height: 100px;
$footer-size: fit-content;
$filters-toolbar-height: 64px;
$block-padding: 16px;
$block-max-width: 1200px;

// Responsive
$lg-card-padding: 8px;
$md-card-padding: 6px;
$sm-card-padding: 6px;
$mobile-xl-card-padding: 6px;
$mobile-l-card-padding: 4px;
$xs-card-padding: 6px;
$mobile-s-card-padding: 4px;
$xxs-card-padding: 6px;
$lg-card-width: 276px;
$md-card-width: 208px;
$sm-card-width: 182px;
$mobile-xl-card-width: 200px;
$mobile-l-card-width: 182px;
$xs-card-width: 182px;
$mobile-s-card-width: 155px;
$xxs-card-width: 148px;
$lg-card-height: 400px;
$md-card-height: 272px;
$sm-card-height: 256px;
$mobile-xl-card-height: 247px;
$mobile-l-card-height: 256px;
$xs-card-height: 256px;
$mobile-s-card-height: 212px;
$xxs-card-height: 203px;
