// Import Material Angular Theme
@import './themes/popsy.variables.scss';
@import './themes/popsy-light.colors.scss';
// @import './themes/popsy-dark.colors.scss';
@import './themes/popsy.mixins.scss';
@import './themes/popsy.theme.scss';
@import '~nouislider/dist/nouislider.min.css';
@import './themes/nunito.font.scss';
@import './themes/cairo.font.scss';
@import '../node_modules/swiper/swiper-bundle.min.css';
@import '../node_modules/@videogular/ngx-videogular/fonts/videogular.css';

:root {
  --ios-gap: 0px;
}

.search-icon.grey {
  > svg {
    path {
      stroke: #42526e;
    }
  }
}

.star-icon.gold {
  > svg {
    path {
      fill: #ffd700;
    }
  }
}

.star-icon.white {
  > svg {
    path {
      fill: #fff;
    }
  }
}

// Popsy Design System - Icon Colors
.icon {
  svg {
    width: 100%;
    height: 100%;
  }

  &.no-fill {
    > svg {
      path,
      rect {
        fill: transparent;
      }
    }
  }

  &.extra-thin {
    > svg {
      path,
      rect {
        stroke-width: 0.5;
      }
    }
  }

  &.thin {
    > svg {
      path,
      rect {
        stroke-width: 1;
      }
    }
  }

  &.thick {
    > svg {
      path,
      rect {
        stroke-width: 2;
      }
    }
  }

  &.primary {
    > svg {
      path,
      rect {
        stroke: popsy-color-palette($popsy-colors-primary, 500) !important;
        fill: none !important;
      }
    }
  }

  &.primary-bright {
    > svg {
      path,
      rect {
        stroke: popsy-color-palette($popsy-colors-primary, 500) !important;
        fill: none !important;
      }
    }
  }

  &.primary-light {
    > svg {
      path,
      rect {
        stroke: popsy-color-palette($popsy-colors-primary, 200) !important;
        fill: none !important;
      }
    }
  }

  &.secondary-dark {
    > svg {
      path,
      rect {
        stroke: popsy-color-palette($popsy-colors-secondary, 500) !important;
        fill: none !important;
      }
    }
  }

  &.secondary {
    > svg {
      path,
      rect {
        stroke: popsy-color-palette($popsy-colors-secondary, 400) !important;
        fill: none !important;
      }
    }
  }

  &.secondary-light {
    > svg {
      path,
      rect {
        stroke: popsy-color-palette($popsy-colors-secondary, 100) !important;
        fill: none !important;
      }
    }
  }

  &.key-color {
    > svg {
      path,
      rect {
        stroke: popsy-color-palette($popsy-colors-secondary, 50) !important;
        fill: none !important;
      }
    }
  }

  &.gray-dark {
    > svg {
      path,
      rect {
        stroke: popsy-color-palette($popsy-colors-gray, 700) !important;
        fill: none !important;
      }
    }
  }

  &.gray-01 {
    > svg {
      path,
      rect {
        stroke: popsy-color-palette($popsy-colors-gray, 500) !important;
        fill: none !important;
      }
    }
  }

  &.gray-02 {
    > svg {
      path,
      rect {
        stroke: popsy-color-palette($popsy-colors-gray, 400) !important;
        fill: none !important;
      }
    }
  }

  &.gray-03 {
    > svg {
      path,
      rect {
        stroke: popsy-color-palette($popsy-colors-gray, 300) !important;
        fill: none !important;
      }
    }
  }

  &.gray-04 {
    > svg {
      path,
      rect {
        stroke: popsy-color-palette($popsy-colors-gray, 200) !important;
        fill: none !important;
      }
    }
  }

  &.gray-background {
    > svg {
      path,
      rect {
        stroke: popsy-color-palette($popsy-colors-gray, 100) !important;
        fill: none !important;
      }
    }
  }

  &.gray-light {
    > svg {
      path,
      rect {
        stroke: popsy-color-palette($popsy-colors-gray, 50) !important;
        fill: none !important;
      }
    }
  }

  // Fill
  &.fill-primary {
    > svg {
      path {
        stroke: none !important;
        fill: popsy-color-palette($popsy-colors-primary, 500) !important;
      }
    }
  }

  &.fill-primary-light {
    > svg {
      path {
        stroke: none !important;
        fill: popsy-color-palette($popsy-colors-primary, 200) !important;
      }
    }
  }

  &.fill-secondary-dark {
    > svg {
      path {
        stroke: none !important;
        fill: popsy-color-palette($popsy-colors-secondary, 500) !important;
      }
    }
  }

  &.fill-secondary {
    > svg {
      path {
        stroke: none !important;
        fill: popsy-color-palette($popsy-colors-secondary, 400) !important;
      }
    }
  }

  &.fill-secondary-light {
    > svg {
      path {
        stroke: none !important;
        fill: popsy-color-palette($popsy-colors-secondary, 100) !important;
      }
    }
  }

  &.fill-key-color {
    > svg {
      path {
        stroke: none !important;
        fill: popsy-color-palette($popsy-colors-secondary, 50) !important;
      }
    }
  }

  &.fill-gray-dark {
    > svg {
      path {
        stroke: none !important;
        fill: popsy-color-palette($popsy-colors-gray, 600) !important;
      }
    }
  }

  &.fill-gray-01 {
    > svg {
      path {
        stroke: none !important;
        fill: popsy-color-palette($popsy-colors-gray, 500) !important;
      }
    }
  }

  &.fill-gray-02 {
    > svg {
      path {
        stroke: none !important;
        fill: popsy-color-palette($popsy-colors-gray, 400) !important;
      }
    }
  }

  &.fill-gray-03 {
    > svg {
      path {
        stroke: none !important;
        fill: popsy-color-palette($popsy-colors-gray, 300) !important;
      }
    }
  }

  &.fill-gray-04 {
    > svg {
      path {
        stroke: none !important;
        fill: popsy-color-palette($popsy-colors-gray, 200) !important;
      }
    }
  }

  &.fill-gray-background {
    > svg {
      path {
        stroke: none !important;
        fill: popsy-color-palette($popsy-colors-gray, 100) !important;
      }
    }
  }

  &.fill-gray-light {
    > svg {
      path {
        stroke: none !important;
        fill: popsy-color-palette($popsy-colors-gray, 50) !important;
      }
    }
  }
}

// Color for the SVG Icons
.icon.red-stroke {
  > svg {
    path {
      stroke: popsy-color-palette($popsy-colors-primary, 500) !important;
      fill: none !important;
    }
  }
}

.icon.white-stroke {
  > svg {
    path {
      stroke: popsy-color-palette($popsy-colors-gray, 50) !important;
      fill: none !important;
    }
  }
}

.icon.gray-stroke {
  > svg {
    path {
      stroke: popsy-contrast-palette($popsy-colors-gray, 200) !important;
      fill: none !important;
    }
  }
}

.icon.black-fill {
  > svg {
    path {
      fill: popsy-color-palette($popsy-colors-gray, 700) !important;
      stroke: none !important;
    }
  }
}

.icon.red-fill {
  > svg {
    path {
      fill: popsy-color-palette($popsy-colors-primary, 500) !important;
      stroke: none !important;
    }
  }
}

.icon.bold {
  > svg {
    path {
      stroke-width: 3px !important;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

a {
  color: unset;
  cursor: pointer;
  text-decoration: unset;
}

.line {
  @include popsy-box();
  border-top: 2px solid popsy-color-palette($popsy-colors-gray, 200);
  width: auto;
  height: 2px;
  min-width: 100%;
  min-height: 2px;
  max-width: 100%;
  max-height: 2px;

  &.dark {
    border-top: 2px solid popsy-color-palette($popsy-colors-gray, 300);
  }

  &.negative {
    border-top: 2px solid popsy-color-palette($popsy-colors-gray, 50);
  }
}

.vertical-line {
  @include popsy-box();
  border-left: 2px solid popsy-color-palette($popsy-colors-gray, 200);
  width: 2px;
  height: auto;
  min-height: 100%;
  min-width: 2px;
  max-height: 100%;
  max-width: 2px;

  &.dark {
    border-left: 2px solid popsy-color-palette($popsy-colors-gray, 300);
  }

  &.negative {
    border-left: 2px solid popsy-color-palette($popsy-colors-gray, 50);
  }
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.fixed-space-4 {
  @include popsy-box();
  width: 4px;
  height: 4px;
  min-width: 4px;
  min-height: 4px;
  max-width: 4px;
  max-height: 4px;
}

.fixed-space-6 {
  @include popsy-box();
  width: 6px;
  height: 6px;
  min-width: 6px;
  min-height: 6px;
  max-width: 6px;
  max-height: 6px;
}

.fixed-space-8 {
  @include popsy-box();
  width: 8px;
  height: 8px;
  min-width: 8px;
  min-height: 8px;
  max-width: 8px;
  max-height: 8px;
}

.fixed-space-12 {
  @include popsy-box();
  width: 12px;
  height: 12px;
  min-width: 12px;
  min-height: 12px;
  max-width: 12px;
  max-height: 12px;
}

.fixed-space-16 {
  @include popsy-box();
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
  max-width: 16px;
  max-height: 16px;
}

.fixed-space-21 {
  @include popsy-box();
  width: 21px;
  height: 21px;
  min-width: 21px;
  min-height: 21px;
  max-width: 21px;
  max-height: 21px;
}

.fixed-space-24 {
  @include popsy-box();
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  max-width: 24px;
  max-height: 24px;
}

.fixed-space-32 {
  @include popsy-box();
  width: 32px;
  height: 32px;
  min-width: 32px;
  min-height: 32px;
  max-width: 32px;
  max-height: 32px;
}

.fixed-space-36 {
  @include popsy-box();
  width: 36px;
  height: 36px;
  min-width: 36px;
  min-height: 36px;
  max-width: 36px;
  max-height: 36px;
}

.fixed-space-48 {
  @include popsy-box();
  width: 48px;
  height: 48px;
  min-width: 48px;
  min-height: 48px;
  max-width: 48px;
  max-height: 48px;
}

.fixed-space-64 {
  @include popsy-box();
  width: 64px;
  height: 64px;
  min-width: 64px;
  min-height: 64px;
  max-width: 64px;
  max-height: 64px;
}

.dynamic-space {
  @include popsy-box();
  flex: 1;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
  max-width: 100%;
  max-height: 100%;
}

.margin-centered {
  margin-left: auto !important;
  margin-right: auto !important;
}

// Colors for the text
.text-black {
  color: popsy-color-palette($popsy-colors-gray, 700);
}

.text-gray-dark {
  color: popsy-color-palette($popsy-colors-gray, 700);
}

.text-gray-05 {
  color: popsy-color-palette($popsy-colors-gray, 500);
}

.text-gray-04 {
  color: popsy-color-palette($popsy-colors-gray, 400);
}

.text-gray-03 {
  color: popsy-color-palette($popsy-colors-gray, 300);
}

.text-gray-02 {
  color: popsy-color-palette($popsy-colors-gray, 200);
}

.text-gray-01 {
  color: popsy-color-palette($popsy-colors-gray, 100);
}

.text-gray-light {
  color: popsy-color-palette($popsy-colors-gray, 50);
}

.text-highlight {
  color: popsy-color-palette($popsy-colors-primary, 500);
}

.text-bright {
  color: popsy-color-palette($popsy-colors-primary, 400);
}

.text-secondary-light {
  color: popsy-color-palette($popsy-colors-secondary, 100);
}

.text-secondary {
  color: popsy-color-palette($popsy-colors-secondary, 200) !important;
}

body.rtl .ltr {
  direction: ltr !important;
}

body {
  @include popsy-box();
  overflow: hidden;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  max-height: calc(100vh);
  max-height: calc(100vh - var(--ios-gap));
  margin: 0;
  padding: 0;
  transform: translateZ(1px);
  background-color: popsy-color-palette($popsy-colors-gray, 50);
  color: popsy-color-palette($popsy-colors-gray, 700);
  -webkit-font-smoothing: subpixel-antialiased;

  font-family: 'Nunito', sans-serif;
  font-display: swap;

  &.rtl {
    direction: rtl !important;

    * {
      direction: rtl !important;

      .noUi-origin,
      .mat-mdc-menu-content,
      .toolbar-user-menu-panel-ltr,
      .cdk-overlay-connected-position-bounding-box {
        direction: initial !important;
      }
    }

    #tidio-chat-iframe {
      bottom: 50px !important;
    }
    @media only screen and (max-width: 980px) {
      #tidio-chat-iframe {
        bottom: 50px !important;
      }
    }
  }

  .stripe-container {
    direction: ltr !important;

    * {
      direction: ltr !important;
    }
  }

  .ltr-container {
    direction: ltr !important;

    * {
      direction: ltr !important;
    }
  }

  app-root {
    @include popsy-box();
    width: 100%;
    height: 100%;
  }

  * {
    font-family: 'Nunito', sans-serif !important;
    font-display: swap;
  }

  &.arabic {
    font-family: 'Cairo', 'Nunito', sans-serif;
    font-display: swap;

    * {
      font-family: 'Cairo', 'Nunito', sans-serif !important;
      font-display: swap;
    }
  }

  .no-script {
    display: block;
    margin-top: 85px;
    width: 100%;
    text-align: center;
    color: popsy-color-palette($popsy-colors-primary, 500);
    font-family: 'Nunito', sans-serif;
    font-display: swap;
  }

  .dynamic-space {
    flex: 1;
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
  }

  .popsy-loading-animation-shine {
    position: relative;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    left: 0;
    text-align: center;

    .toolbar-mock {
      @include popsy-box();

      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: $header-size;
      background-color: popsy-color-palette($popsy-colors-gray, 50);
      z-index: 100;
      box-shadow: 0 2px 8px 0 rgba(6, 52, 143, 0.1);

      .logo-mock {
        @include popsy-ui-skeleton();
        float: left;
        height: 42px;
        width: 42px;
        margin: 11px 0 0 16px;
        border-radius: 5px;
      }

      .search-mock {
        @include popsy-ui-skeleton();
        float: left;
        border-radius: 8px;
        margin: 16px 0 0 32px;
        height: 32px;
        width: 350px;
        max-width: calc(100% - 120px);
      }
    }

    .animation {
      width: 100%;
      text-align: center;

      .shapes {
        width: 49px;
        height: 49px;
        border-radius: 50%;
        display: inline-block;
        position: relative;
        vertical-align: middle;
        animation: rotate 1.15s infinite linear;

        &::before,
        &::after {
          content: '';
          width: 100%;
          height: 100%;
          border-radius: 50%;
          position: absolute;
          top: 0;
          left: 0;
          animation: 1.15s infinite ease-in-out;
        }

        &::before,
        &::after {
          content: '';
          width: 80%;
          height: 80%;
          background-color: rgba(popsy-color-palette($popsy-colors-primary, 500), 0.4);
          top: 5%;
        }

        &::before {
          left: -5%;
          animation: scale 1.15s infinite alternate ease-in-out;
          transform-origin: 10% 50%;
        }

        &::after {
          left: auto;
          right: -5%;
          animation: scale 1.15s 1.15s infinite alternate ease-in-out;
          transform: scale(0);
          transform-origin: 90% 50%;
        }
      }

      @keyframes rotate {
        0% {
          transform: rotate(20deg);
        }

        100% {
          transform: rotate(380deg);
        }
      }

      @keyframes scale {
        0% {
          transform: scale(0);
        }

        100% {
          transform: scale(1);
        }
      }
    }
  }
}

h1 {
  @include popsy-text-title();
}

h2 {
  @include popsy-text-subheading-2();
}

h3 {
  @include popsy-text-subheading-1();
}

h4 {
  @include popsy-text-body-2();
}

span {
  @include popsy-text-body-1();
}

span.small {
  @include popsy-text-caption();
}

.bold {
  font-weight: bold;
}

.light {
  font-weight: 400;
}

.italic {
  font-style: italic;
}

.crossed {
  text-decoration: line-through;
}

.text-center {
  text-align: center;
}

.nowrap {
  white-space: nowrap;
}

.wrap {
  white-space: pre-line;
}

// Remove all webkit modifications to search type input
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}

// Disable Hover effect on material buttons that have this class
// (this is global as recommended by angular due to the /deep/ deprecation)
.pink-ripple .mat-button-focus-overlay {
  background-color: transparent;
}

.pink-ripple .mat-ripple-element {
  background-color: popsy-color-palette($popsy-colors-primary, 500) !important;
  opacity: 0.4 !important;
}

.pink-ripple {
  overflow: hidden !important;
}

// Disable scroll when the backdrop is showing
.mat-drawer-backdrop {
  touch-action: none;
}

// Match Dialogs border radios (from old frontend)
.mat-dialog-container-round,
%mat-dialog-container-round {
  overflow: hidden;
  border-radius: 10px;

  .mat-mdc-dialog-container {
    padding: 0;
  }
}

.mat-dialog-container-round-no-scroll {
  @extend %mat-dialog-container-round;

  .mat-mdc-dialog-container {
    overflow: hidden;
  }
}

.mat-dialog-container-filters {
  overflow: hidden;
  border-radius: 0;

  .mat-mdc-dialog-container {
    padding: 0;
    border-radius: 0;
  }
}

// style google auto complete
.pac-container {
  max-width: calc(100vw - 16px);
  margin-left: -40px;
  margin-top: 16px;
  border: 0 none;
  border-radius: 5px;
  box-shadow: 0 0 6px 0 rgba(#282832, 0.2);

  &::after {
    height: 0;
    padding: 0;
    background-image: none;
  }
}

.pac-item {
  border-top: 0 none;
  padding: 0 10px;
  font-size: 13px;
  line-height: 36px;
  cursor: pointer;
}

.pac-item-query {
  font-size: 15px !important;
}

.pac-icon {
  display: none;
}

// Move the user menu down so it doesn't overlap the button
.toolbar-user-menu-panel {
  margin-top: 35px;

  .mat-mdc-menu-content:not(:empty) {
    padding-top: 0;
    padding-bottom: 0;

    .sign-out {
      .label {
        color: popsy-color-palette($popsy-colors-primary, 500);
      }
    }
  }
}

.toolbar-user-menu-panel-ltr {
  direction: initial !important;
  margin-top: 35px;

  .mat-mdc-menu-content:not(:empty) {
    padding-top: 0;
    padding-bottom: 0;

    .sign-out {
      .label {
        color: popsy-color-palette($popsy-colors-primary, 500);
      }

      mat-icon {
        transform: rotateY(-180deg);
        backface-visibility: visible;
      }
    }
  }
}

// Center grid Items
.regular-size-grid-component .grid-viewport > div.scrollable-content {
  min-width: 320px !important;
}

// Checkbox
.popsy-checkbox-component {
  border-radius: 5px;
  font-size: 16px;
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  color: popsy-color-palette($popsy-colors-gray, 600) !important;

  &:hover {
    background-color: rgba(
      popsy-color-palette($popsy-colors-primary, 200),
      0.2
    ) !important;
  }

  &.selected {
    background-color: rgba(
      popsy-color-palette($popsy-colors-primary, 200),
      0.2
    ) !important;
  }

  .mat-checkbox-inner-container {
    width: 24px;
    height: 24px;
  }

  &.mat-checkbox-checked {
    .mat-checkbox-background {
      background-color: popsy-color-palette($popsy-colors-primary, 500) !important;
    }
  }

  .mat-checkbox-frame {
    border-color: popsy-color-palette($popsy-colors-primary, 200) !important;
    border-width: 1px;
    width: 24px;
    height: 24px;
  }

  .mat-ripple-element {
    background-color: popsy-color-palette($popsy-colors-primary, 200) !important;
  }

  label,
  .mat-checkbox-label {
    width: 100%;
  }
}

// Radio button style
.popsy-radio-button-component {
  .option-list-item {
    border-radius: 5px;
    font-size: 16px;

    &:hover {
      background-color: rgba(
        popsy-color-palette($popsy-colors-primary, 200),
        0.2
      ) !important;
    }

    &.selected {
      background-color: rgba(
        popsy-color-palette($popsy-colors-primary, 200),
        0.2
      ) !important;
    }
  }

  .mat-radio-container {
    width: 24px;
    height: 24px;
  }

  .mat-radio-inner-circle {
    background-color: popsy-color-palette($popsy-colors-primary, 500) !important;
    width: 24px;
    height: 24px;
  }

  .mat-radio-checked {
    .mat-radio-inner-circle {
      transform: scale(1);
    }
  }

  .mat-radio-outer-circle {
    border-color: popsy-color-palette($popsy-colors-primary, 200) !important;
    border-width: 1px;
    width: 24px;
    height: 24px;
  }

  .mat-ripple-element {
    background-color: popsy-color-palette($popsy-colors-primary, 200) !important;
  }
}

.popsy-button-primary {
  @include popsy-button();
  background-color: popsy-color-palette($popsy-colors-gray, 700) !important;
  color: popsy-contrast-palette($popsy-colors-secondary, 800) !important;
  border: 0;
  height: $popsy-input-height;
  line-height: $popsy-input-height;
  border-radius: 6px;
  cursor: pointer;

  .text {
    white-space: nowrap;
    word-break: break-all;
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
  }

  .mat-button-wrapper {
    font-weight: bold;
    height: 100%;
    width: 100%;
  }

  .mat-ripple-element {
    background-color: rgba(
      popsy-color-palette($popsy-colors-secondary, 400),
      0.2
    ) !important;
  }

  &:hover {
    background-color: lighten(
      popsy-color-palette($popsy-colors-primary, 500),
      8%
    ) !important;
  }

  &:active {
    background-color: lighten(
      popsy-color-palette($popsy-colors-secondary, 400),
      10%
    ) !important;
  }

  &.pill {
    border-radius: 32px;
  }

  &.highlight {
    background-color: popsy-color-palette($popsy-colors-primary, 400) !important;

    &.outlined {
      background-color: unset !important;
      color: popsy-color-palette($popsy-colors-primary, 400) !important;
      border: 1px solid popsy-color-palette($popsy-colors-primary, 400) !important;
    }

    &:active,
    &:hover {
      background-color: popsy-color-palette($popsy-colors-primary, 500) !important;
      color: popsy-color-palette($popsy-colors-primary, 50) !important;
    }
  }

  &.blue-button {
    background-color: #31abf7 !important;
  }

  &.cta {
    width: 235px;
    height: 45px;
  }

  &[disabled] {
    background-color: rgba(popsy-color-palette($popsy-colors-gray, 600), 0.1) !important;
    border-color: rgba(popsy-color-palette($popsy-colors-gray, 600), 0.1);
    color: rgba(popsy-color-palette($popsy-colors-gray, 600), 0.6) !important;
  }
}

.popsy-button-secondary,
%popsy-button-secondary {
  @include popsy-button();
  background-color: popsy-color-palette($popsy-colors-gray, 300) !important;
  color: popsy-color-palette($popsy-colors-gray, 50) !important;
  border: 0;
  height: $popsy-input-height;
  font-weight: bold;
  cursor: pointer;

  .text {
    white-space: nowrap;
    word-break: break-all;
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
  }

  .mat-button-wrapper {
    height: $popsy-input-height;
    font-weight: bold;
  }

  .mat-ripple-element {
    background-color: rgba(popsy-color-palette($popsy-colors-gray, 600), 0.2) !important;
  }

  .mat-button-focus-overlay {
    background: none !important;
  }

  &:hover {
    background-color: popsy-color-palette($popsy-colors-primary, 500) !important;
  }

  &.blue {
    background-color: popsy-color-palette($popsy-colors-secondary, 100) !important;
    color: popsy-color-palette($popsy-colors-primary, 50) !important;

    &:hover {
      background-color: lighten(
        popsy-color-palette($popsy-colors-primary, 500),
        8%
      ) !important;
    }

    &:active {
      background-color: lighten(
        popsy-color-palette($popsy-colors-primary, 500),
        10%
      ) !important;
    }
  }

  &.pill {
    border-radius: 32px;
  }

  &[disabled] {
    border-color: rgba(popsy-color-palette($popsy-colors-gray, 500), 0.2) !important;
    color: rgba(popsy-color-palette($popsy-colors-gray, 500), 0.2) !important;
  }
}

.popsy-button-icon {
  @include popsy-button();
  background-color: transparent !important;
  border: unset !important;
  line-height: $popsy-input-height !important;
  padding: 8px 11px;
  width: $header-size - 25px;
  min-width: $header-size - 25px;
  max-width: $header-size - 25px;
  height: $header-size - 25px;
  min-height: $header-size - 25px;
  max-height: $header-size - 25px;
  cursor: pointer;

  .icon {
    @include mat-icon-size($header-size - 45px);

    ::ng-deep g {
      stroke: popsy-color-palette($popsy-colors-gray, 700) !important;
    }
  }

  .text {
    white-space: nowrap;
    word-break: break-all;
    text-overflow: ellipsis;
    width: 100%;
    display: block;
    overflow: hidden;
  }

  .mat-button-wrapper {
    height: $popsy-input-height;
    font-weight: bold;
  }

  .mat-ripple-element {
    background-color: rgba(popsy-color-palette($popsy-colors-gray, 600), 0.2) !important;
  }

  .mat-button-focus-overlay {
    background: none !important;
  }

  &:active,
  &:hover {
    background-color: rgba(popsy-color-palette($popsy-colors-gray, 400), 0.2) !important;
  }

  &.pill {
    border-radius: 32px;
  }

  &[disabled] {
    border-color: rgba(popsy-color-palette($popsy-colors-gray, 500), 0.2) !important;
    color: rgba(popsy-color-palette($popsy-colors-gray, 500), 0.2) !important;
  }
}

.popsy-button-option {
  @extend %popsy-button-secondary;
  border: 1px solid popsy-color-palette($popsy-colors-gray, 300) !important;
  color: popsy-color-palette($popsy-colors-gray, 400) !important;
  background-color: transparent !important;
  cursor: pointer;

  .text {
    white-space: nowrap;
    word-break: break-all;
    text-overflow: ellipsis;
    width: 100%;
    display: block;
    overflow: hidden;
  }

  &:hover {
    background-color: rgba(popsy-color-palette($popsy-colors-gray, 400), 0.1) !important;
  }

  &:active {
    background-color: rgba(popsy-color-palette($popsy-colors-gray, 400), 0.2) !important;
  }

  &:focus {
    background-color: rgba(popsy-color-palette($popsy-colors-gray, 400), 0.1) !important;
  }

  &[disabled='true'] {
    color: rgba(popsy-color-palette($popsy-colors-gray, 400), 0.2) !important;
  }
}

.popsy-input-field {
  @include popsy-box();
  height: 40px;
  border-radius: 5px;
  outline: none;
  border: 1px solid popsy-color-palette($popsy-colors-gray, 400);
  padding: 8px 16px;

  &:focus {
    border-color: popsy-color-palette($popsy-colors-gray, 300);
  }

  &.error {
    border-color: popsy-color-palette($popsy-colors-primary, 500);

    &:focus {
      border-color: popsy-color-palette($popsy-colors-primary, 400);
    }
  }
}

.popsy-text-field {
  input,
  .field {
    @include popsy-text-caption();
    @include popsy-box();
    border: 0;
    width: 100%;
    height: 100%;
    text-align: start;
    padding: 0 8px;
    appearance: unset;
    color: popsy-color-palette($popsy-colors-gray, 600);
    border: 1px solid rgba(popsy-color-palette($popsy-colors-gray, 300), 0.4) !important;

    &:focus::placeholder {
      transition: opacity 0.5s 0.5s ease;
      opacity: 1;
    }

    &.field-button {
      cursor: pointer;

      &:hover {
        background-color: popsy-color-palette($popsy-colors-gray, 100);
      }
    }

    &::placeholder {
      @include popsy-text-caption();
      color: popsy-color-palette($popsy-colors-gray, 300);
      line-height: -moz-block-height;
    }

    &:focus {
      outline: none;
      border-color: inherit;
      box-shadow: none;
      box-shadow: none;
    }

    &[disabled] {
      background-color: rgba(popsy-color-palette($popsy-colors-gray, 200), 0.4);
      color: rgba(popsy-color-palette($popsy-colors-gray, 600), 0.4);

      &::placeholder {
        color: rgba(popsy-color-palette($popsy-colors-gray, 600), 0.4);
        border: 0 !important;
      }

      &.field-button {
        background-color: popsy-color-palette($popsy-colors-gray, 100);
      }
    }
  }
}

@keyframes shine {
  to {
    opacity: 1;
    right: 210%;
  }
}

.popsy-snack {
  @include popsy-text-caption();
  background-color: popsy-color-palette($popsy-colors-gray, 600);
  max-width: 80vw !important;
  width: fit-content !important;

  .mat-mdc-snack-bar-action {
    margin: -8px -8px -8px 16px;
  }
}

.popsy-snack-light {
  @include popsy-text-caption();
  background-color: popsy-color-palette($popsy-colors-gray, 50);
  width: fit-content !important;
  margin-top: 80px !important;
  max-width: 300px !important;

  .mat-mdc-snack-bar-action {
    margin: -8px -8px -8px 16px;
  }
}

.mat-mdc-snack-bar-handset .popsy-snack {
  max-width: 100vw !important;
  width: 100% !important;
}

.mat-mdc-snack-bar-handset .popsy-snack-light {
  max-width: 100vw !important;
  width: 100% !important;
}

.popsy-tooltip {
  @include popsy-text-caption();
  background-color: popsy-color-palette($popsy-colors-gray, 500);
}

.popsy-overlay-panel {
  border-radius: 8px;
}

.popsy-white-opaque-backdrop {
  background-color: rgba(popsy-color-palette($popsy-colors-gray, 50), 0.5);
}

.popsy-white-extra-opaque-backdrop {
  background-color: rgba(popsy-color-palette($popsy-colors-gray, 50), 0.8);
}

.popsy-dark-opaque-backdrop {
  background-color: rgba(popsy-color-palette($popsy-colors-gray, 600), 0.6);
}

.popsy-overlay-panel-full-height-no-header {
  border-radius: 8px;
  min-height: calc(100vh - 88px);
}

.popsy-overlay-panel-full-height {
  min-height: 100vh;
  min-height: calc(100vh - var(--ios-gap));
}

.popsy-dialog-overlay {
  opacity: 0.2 !important;
  background-color: popsy-color-palette($popsy-colors-gray, 600);
}

.popsy-link {
  color: popsy-color-palette($popsy-colors-primary, 500);
  text-decoration: underline;
  cursor: pointer;

  &.gray {
    color: popsy-color-palette($popsy-colors-gray, 500);
  }

  &:hover {
    color: popsy-color-palette($popsy-colors-gray, 400);
    text-decoration: underline;
  }
}

.listing-edit-preview-menu-panel {
  min-height: 32px !important;
  margin-bottom: 8px;

  > div {
    padding: 0 !important;
  }
}

.popsy-edit-listing-menu {
  @include popsy-box();
  min-height: fit-content !important;

  .mat-mdc-menu-content {
    padding: 0 !important;
  }

  .edit-menu-item {
    @include popsy-box();
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-height: 32px !important;

    .icon {
      @include mat-icon-size(16px);
      margin: 0;
    }

    .title {
      @include popsy-text-caption();
    }
  }
}

.popsy-mat-menu-question {
  @include popsy-text-caption();
  @include popsy-box();
  min-width: fit-content;
  min-height: fit-content;
  padding: 8px 16px;
}

.popsy-autocomplete-container {
  &.mat-autocomplete-visible {
    height: fit-content;
    min-height: 180px;
    max-height: 276px;
  }

  .popsy-autocomplete-option {
    @include popsy-text-caption();
    font-size: 16px;
    color: popsy-color-palette($popsy-colors-gray, 600);

    &.mat-active {
      font-weight: bold;
      color: popsy-color-palette($popsy-colors-gray, 600);
      background-color: rgba(popsy-color-palette($popsy-colors-secondary, 50), 0.2);

      &.loading,
      &.google-maps-attribution {
        background-color: inherit;
        pointer-events: none;
      }
    }
  }

  .google-maps-attribution {
    @include popsy-text-caption();
    @include popsy-box();
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 34px;
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: popsy-color-palette($popsy-colors-gray, 300);
    padding: 0 2px;
    margin-top: 2px;
    text-align: end;
    padding: 8px 16px;
    background-color: inherit;
    pointer-events: none;
  }
}

.popsy-select-field {
  @include popsy-select-field();
}

.popsy-input {
  @include popsy-box();
  display: flex;
  flex-direction: column;
  height: fit-content;

  .label {
    @include popsy-text-caption();
    @include popsy-box();
    height: 18px;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 18px;
    letter-spacing: normal;
    color: popsy-color-palette($popsy-colors-gray, 400);
    padding: 0 2px;
    margin-bottom: 2px;
    text-overflow: ellipsis;
    word-break: break-all;
    white-space: nowrap;
    overflow: hidden;

    &.skeleton {
      width: 50%;
      padding: 0;
    }
  }

  .error-label {
    @include popsy-text-caption();
    @include popsy-box();
    height: 18px;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: popsy-color-palette($popsy-colors-primary, 600);
    padding: 0 2px;
    margin-top: 2px;
  }

  .field-skeleton {
    width: 100%;
    padding: 0;
    border-radius: 6px;
    height: 40px;
  }

  .field {
    @include popsy-text-caption();
    @include popsy-box();
    width: 100%;
    height: 100%;
    min-height: 40px;
    text-align: start;
    padding: 10px 12px;
    appearance: unset;
    color: popsy-color-palette($popsy-colors-gray, 600);
    caret-color: popsy-color-palette($popsy-colors-secondary, 500);
    background-color: popsy-color-palette($popsy-colors-gray, 50);
    border: 1px solid rgba(popsy-color-palette($popsy-colors-gray, 300), 0.4) !important;
    border-radius: 6px;
    resize: none;

    &:focus::placeholder {
      transition: opacity 0.5s 0.5s ease;
      opacity: 1;
    }

    &.field-button {
      cursor: pointer;

      &:hover {
        background-color: popsy-color-palette($popsy-colors-gray, 100);
      }
    }

    &::placeholder {
      @include popsy-text-caption();
      color: popsy-color-palette($popsy-colors-gray, 300);
      line-height: -moz-block-height;
    }

    &:focus {
      outline: none;
      border-color: inherit !important;
      box-shadow: none;

      border: 1px solid popsy-color-palette($popsy-colors-secondary, 50) !important;
      background-color: popsy-color-palette($popsy-colors-gray, 50);
    }

    &[disabled] {
      background-color: rgba(popsy-color-palette($popsy-colors-gray, 200), 0.4);
      color: rgba(popsy-color-palette($popsy-colors-gray, 400), 0.4);

      &::placeholder {
        color: rgba(popsy-color-palette($popsy-colors-gray, 400), 0.4);
        border: 0 !important;
      }

      &.field-button {
        background-color: popsy-color-palette($popsy-colors-gray, 100);
      }
    }
  }

  &.error {
    .label {
      color: popsy-color-palette($popsy-colors-primary, 600);
    }

    .field {
      border-color: popsy-color-palette($popsy-colors-primary, 600) !important;
    }
  }
}

.input-label {
  @include popsy-text-caption();
  @include popsy-box();
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 18px;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 18px;
  letter-spacing: normal;
  color: popsy-color-palette($popsy-colors-gray, 400);
  padding: 0 2px;
  margin-bottom: 2px;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: nowrap;
  overflow: hidden;

  .required-indicator {
    @include popsy-text-caption();
    @include popsy-box();
    color: popsy-color-palette($popsy-colors-primary, 600);
  }

  &.skeleton {
    width: 50%;
    padding: 0;
  }
}

.popsy-text-caption {
  @include popsy-text-caption();
}

.popsy-card {
  box-sizing: border-box;
  border-radius: 8px;
  overflow: hidden;
  padding: 8px 16px;
  background-color: popsy-color-palette($popsy-colors-gray, 50);
  box-shadow: 0 3px 16px -3px rgba(0, 0, 0, 0.12);

  &.light-gray {
    background-color: popsy-color-palette($popsy-colors-gray, 200);
    box-shadow: unset;
  }

  &.dark-gray {
    background-color: popsy-color-palette($popsy-colors-gray, 700);
    color: popsy-color-palette($popsy-colors-gray, 50);
    box-shadow: unset;
  }

  &.secondary {
    background-color: popsy-color-palette($popsy-colors-secondary, 50);
  }

  &.transparent {
    background-color: unset;
    box-shadow: unset;
  }

  &.wire {
    background-color: unset;
    box-shadow: unset;
    border: 1px solid rgba(165, 173, 185, 0.4);
  }
}

.popsy-block {
  @include popsy-block;
}

.popsy-negative {
  background-color: popsy-color-palette($popsy-colors-primary, 200);
  color: popsy-color-palette($popsy-colors-gray, 50);

  &.dark-gray {
    background-color: popsy-color-palette($popsy-colors-gray, 700);
  }

  &.mid-gray {
    background-color: popsy-color-palette($popsy-colors-gray, 300);
  }
}

.popsy-checkbox {
  cursor: pointer;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.margin-0 {
  margin: 0;
}

.margin-0-auto {
  margin: 0 auto;
}

.padding-0 {
  padding: 0;
}

.border-box {
  box-sizing: border-box;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.flex {
  display: flex;
}

.flex-grow {
  flex: 1;
}

.br-0 {
  border-radius: 0;
}

.m-0 {
  margin: 0;
}

.p-0 {
  padding: 0;
}

.p-8 {
  padding: 8px;
}

.p-16 {
  padding: 16px;
}

.p-32 {
  padding: 32px;
}

.p-32-64 {
  padding: 32px 64px;
}

.p-48 {
  padding: 48px;
}

.p-64 {
  padding: 64px;
}

.p-0-32 {
  padding: 0 32px;
}

.p-0-16 {
  padding: 0 16px;
}

.gap-4 {
  gap: 4px;
}

.gap-8 {
  gap: 8px;
}

.gap-12 {
  gap: 12px;
}

.gap-16 {
  gap: 16px;
}

.gap-24 {
  gap: 24px;
}

.gap-32 {
  gap: 32px;
}

.flex-col {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.flex-wrap {
  flex-wrap: wrap;
}

.justify-center {
  justify-content: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-between {
  justify-content: space-between;
}

.justify-evenly {
  justify-content: space-evenly;
}

.align-center {
  align-items: center;
}

.align-start {
  align-items: flex-start;
}

.align-end {
  align-items: flex-end;
}

.align-base {
  align-items: baseline;
}

.tabby-promo-snippet {
  max-width: 100% !important;
}

/* stylelint-disable */
div#credential_picker_container {
  top: 132px;
}

.cdk-global-scrollblock {
  overflow: hidden !important;
}

/* stylelint-enable */
